<template>
  <div>
    <div class="row mb-3">
      <!-- Select para filtrar veículos -->
      <div class="form-group col-md-3">
        <label for="centrals"> {{ $t("Centrals") }}: </label>
        <v-select
          :options="centers"
          :reduce="(centers) => (centers ? centers.id : null)"
          :label="'name'"
          v-model="center_id"
          class="vselect"
          v-on:input="changetUnitsCenter"
        />
      </div>
      <div class="form-group col-md-3">
        <label for="unit"> {{ $t("Unit") }}: </label>

        <v-select
          :options="units"
          :reduce="(units) => (units ? units.id : null)"
          :label="'name'"
          v-model="unit_id"
          class="vselect"
          v-on:input="filterVehicles"
        />
      </div>
      <div class="form-group col-md-3">
        <label for="filter"> Filtrar por veículo </label>

        <v-select
          :options="optionsWithAll"
          :reduce="(vehicle) => (vehicle ? vehicle.id : null)"
          :label="'name'"
          v-model="selectedVehicle"
          class="vselect"
          v-on:input="filterVehicles"
        />

        <span v-if="elapsedTime">Tempo decorrido: {{ elapsedTime }}s</span>
      </div>
      <!-- 
            <div class="col-md-3 text-center mt-1 ">
              <b-button variant="primary" @click="startTimer" v-if="!timerRunning">
                <b-badge variant="success"> {{ vehicles.length }}</b-badge> Play <i class="fa fa-play"></i>
              </b-button>
              <b-button variant="warning" @click="stopTimer" v-if="timerRunning">
                <b-badge variant="primary"> {{ vehicles.length }}</b-badge> Pause <i class="fa fa-pause"></i>
              </b-button>
              
            </div> -->

      <!-- Mapa com os veículos -->
      <div class="col-12">
        <!-- <VehicleMap
           
            :centerProp="getCenterProp"
            :vehicles="vehicles"
            :notificationDate="notificationDate"
            :ray="ray"
            :areaTypeId="areaTypeId"
            :zoom="zoom"
            :polygonCoordinates="polygonCoordinates"
            @reload="handleReload"
            @zoomChanged="handleZoomChanged"
            @mapClicked="handleMapClicked"
            @vehicleClicked="handleVehicleClicked"
            @vehicleDragged="handleVehicleDragged"
            @centerMarkerDragged="handleCenterMarkerDragged"
          /> -->
        <MapPositionNowPointsList
          :zoomMapa="getZoom"
          :vehicleFleet="getVehicleFleetData"
          @reload="reloadVehicleFleet"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MapPositionNowPointsList from "../layouts/Mapa/MapPositionNowPointsList.vue";
import VehicleMap from "../layouts/Mapa/VehicleMap.vue";
import { BFormSelect, BFormGroup, BButton } from "bootstrap-vue";
import axios from "axios";

export default {
  data() {
    return {
      vehicles: [], // Lista de veículos a ser exibida no mapa
      vehicleslist: [], // Lista de veículos a ser exibida no mapa
      result: [], // Dados completos dos veículos obtidos da API
      centerProp: null, // Centro do mapa
      notificationDate: "",
      vehicle_fleet: [],
      data_filer_fleet: {},
      ray: 5,
      areaTypeId: 1,
      zoom: 13,
      polygonCoordinates: [], // Coordenadas do polígono, se necessário
      selectedVehicle: "", // ID do veículo selecionado para filtro
      intervalId: null, // ID do intervalo para o timer
      elapsedTime: 0, // Tempo decorrido em segundos
      timerRunning: false, // Status do timer (se está em execução ou não)
      centers: [],
      units: [],
      unit_id: "",
      center_id: "",
    };
  },
  components: {
    VehicleMap,
    BFormSelect,
    BFormGroup,
    BButton,
    MapPositionNowPointsList,
  },
  computed: {
    getVehicleFleetData() {
      return this.vehicle_fleet ?? [];
    },
    optionsWithAll() {
      // Adiciona a opção "Todos" no início da lista
      return [{ id: null, name: "Todos" }, ...this.vehicleslist];
    },
    getZoom() {
      return this.zoom;
    },
  },
  methods: {
    getZoomMapa() {
      if (this.selectedVehicle != "") {
        this.zoom = 5;
      }
      if (this.unit_id == "") {
        this.zoom = 10;
      }
      if (this.center_id == "") {
        this.zoom = 15;
      }

      this.zoom = 5;
    },
    getCenters() {
      let api = this.$store.state.api + "centers/";
      axios
        .get(api)
        .then((response) => {
          this.centers = [{ id: null, name: "Todos" }, ...response.data.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    changetUnitsCenter(center_id) {
      this.unit_id = "";
      if (center_id) {
        this.getUnitsCenter(center_id);
      }
      this.filterVehicles();
    },
    getUnitsCenter(center_id) {
      const api = `${this.$store.state.api}centers/unit/${center_id}`;
      axios
        .get(api)
        .then((response) => {
          this.units = [{ id: null, name: "Todos" }, ...response.data];
        })
        .catch((error) => {
          this.$error(error);
        });
    },
    async getVehicles() {
      try {
        const api = this.$store.state.api + "vehicles";
        const response = await axios.get(api);
        console.log("Resposta completa da API:", response); // Verifica a estrutura da resposta completa

        // Verifique se os dados realmente existem antes de tentar acessá-los
        if (response.data) {
          this.result = response.data.data;
          this.vehicleslist = response.data.data;
          console.log("Dados da API (result):", this.result); // Verifica se `this.result` está correto
          // this.vehicles =response.data.data
          // this.vehicles = this.formatVehicleData(this.result);
          // console.log("Veículos formatados:", this.vehicles); // Verifica a lista de veículos formatados
          // this.updateCenter(this.vehicles);
        } else {
          console.warn(
            "A resposta da API não contém 'data.data'. Verifique a estrutura da resposta."
          );
          this.result = []; // Definindo como array vazio para evitar erros posteriores
        }
      } catch (error) {
        console.error("Erro ao obter veículos:", error);
      }
    },
    reloadVehicleFleet: function () {
      this.getVehicleFleet();
    },
    getVehicleFleet() {
      this.vehicle_fleet = [];
      const data = {
        center_id: this.center_id,
        unit_id: this.unit_id,
        vehicle_id: this.selectedVehicle,
      };

      const api = this.$store.state.api + "vehicles/fleet";

      axios
        .post(api, data)
        .then((response) => {
          this.vehicle_fleet = response.data;
          console.log("response", response);
          console.log("self.Vehicle_fleet", this.vehicle_fleet);
        })
        .catch((error) => {
          this.$message(null, error.response.data, "error");
        });
    },
    filterVehicles() {
      let filteredVehicles = this.result;

      // Filtra por unidade e centro se ambos estiverem definidos
      if (this.center_id && this.unit_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) =>
            vehicle.center_id === this.center_id &&
            vehicle.unit_id === this.unit_id
        );
      }
      // Filtra apenas pelo centro se somente `center_id` estiver definido
      else if (this.center_id) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.center_id === this.center_id
        );
      }
      // Filtra apenas pelo veículo selecionado se `selectedVehicle` estiver definido
      else if (this.selectedVehicle) {
        filteredVehicles = filteredVehicles.filter(
          (vehicle) => vehicle.id === this.selectedVehicle
        );
      }
      this.vehicleslist = filteredVehicles;
      // Atualiza a lista de veículos formatados e o centro do mapa
      // this.vehicles = this.formatVehicleData(filteredVehicles);
      // this.updateCenter(this.vehicles);

      this.getVehicleFleet();
      this.getZoomMapa();
    },

    formatVehicleData(rawVehicleData) {
      // Transformar dados para o formato esperado pelo mapa
      return rawVehicleData.map((vehicle) => ({
        id: vehicle.id,
        name: vehicle.name,
        license_plate: vehicle.license_plate,
        lastsampletime: vehicle.position.lastsampletime,
        center_id: vehicle.center_id,
        unit_id: vehicle.unit_id,
        position: {
          lat: parseFloat(vehicle.position.coord.lat),
          lng: parseFloat(vehicle.position.coord.lon),
        },
        icon: vehicle.vehicle_image_url || "default-icon.png", // Exibe imagem do veículo
      }));
    },
    updateCenter(vehicles) {
      // Atualiza o centro do mapa com base no último veículo ou padrão se a lista estiver vazia
      if (vehicles.length > 0) {
        const lastVehicle = vehicles[vehicles.length - 1];
        const dateFormat = moment(`${lastVehicle.lastsampletime}`).format(
          "DD/MM/YYYY HH:mm:ss"
        );

        this.notificationDate = dateFormat;
        this.centerProp = {
          lat: lastVehicle.position.lat,
          lng: lastVehicle.position.lng,
        };
      } else {
        this.centerProp = null;
      }
    },
    handleReload(value) {
      this.getVehicles();
    },
    handleZoomChanged(newZoom) {
      // Lógica para ajuste de zoom
    },
    handleMapClicked(coords) {
      // Lógica para clique no mapa
    },
    handleVehicleClicked(vehicle) {
      console.log("vehicle", vehicle);
      const id = vehicle.id;
      const route = `/vehicles/view/${id}`;
      console.log("route", route);
      this.$router.push(route);
    },
    handleVehicleDragged(data) {
      // Lógica para arrastar o veículo
    },
    handleCenterMarkerDragged(coords) {
      // Lógica para arrastar o marcador central
    },
  },
  mounted() {
    this.getCenters();
    // this.getUnitsCenter()
    this.getVehicles(); // Carrega os dados ao montar o componente
    this.getVehicleFleet(); // Carrega os dados ao montar o componente
  },
  beforeDestroy() {},
};
</script>

<style scoped>
.controls {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
